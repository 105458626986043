interface Props {
  email: string;
  groupId: string;
  recaptcha: string;
}

export async function addSubscriber({ email, groupId, recaptcha }: Props) {
  const url = "/api/wp-json/custom/v1/add-subscriber";
  const body = JSON.stringify({
    email,
    group_id: groupId,
    recaptcha,
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    });

    if (!response.ok) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
}
