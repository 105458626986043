import { cx } from "class-variance-authority";

import { pushDataLayer } from "@bs/data-layer";
import { BaseButton } from "@bs/ui";
import * as React from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { addSubscriber } from "./addSubscriber";
import { NewsletterData, NewsletterForm } from "./types";

export function Newsletter({
  title,
  description,
  variant,
  actionText,
  legal,
  groupId,
  successMessage,
}: NewsletterData) {
  const [token, setToken] = React.useState("");
  const [sendingStatus, setSendingStatus] = React.useState<
    "ERROR" | "PENDING" | "SUCCESS" | null
  >(null);
  const [hasActionStart, setHasActionStart] = React.useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<NewsletterForm>({ mode: "onSubmit" });

  const onSubmit = async (data: NewsletterForm) => {
    if (!hasActionStart) {
      pushDataLayer([
        {
          event: "newsletter_start",
          groupId,
        },
      ]);
      setHasActionStart(true);
    }
    setSendingStatus("PENDING");

    const status = await addSubscriber({
      email: data.email,
      recaptcha: token,
      groupId,
    });

    setRefreshReCaptcha(!refreshReCaptcha);

    if (!status) {
      setSendingStatus("ERROR");
      return;
    }

    setSendingStatus("SUCCESS");
    reset();

    pushDataLayer([
      {
        event: "newsletter_send",
        groupId,
      },
    ]);
  };

  const setTokenFunc = React.useCallback((getToken: string) => {
    setToken(getToken);
  }, []);

  const onFocus = React.useCallback(() => {
    if (hasActionStart) {
      return;
    }

    setHasActionStart(true);
    pushDataLayer([
      {
        event: "newsletter_start",
        groupId,
      },
    ]);
  }, [groupId, hasActionStart]);

  const isLight = variant === "LIGHT";

  if (sendingStatus === "SUCCESS") {
    return (
      <div
        className={cx(
          "w-full border-t-4 border-secondary dark:border-primary",
          isLight ? "bg-secondary text-primary" : "bg-primary text-secondary"
        )}
      >
        <div className="container relative py-8 from-desktop:py-10">
          <div className="pb-5 flex-col gap-5">
            <h2 className="text-xl from-desktop:text-2xl font-semibold text-green-500">
              Adres dodany pomyślnie!
            </h2>
            {successMessage && (
              <p
                className={cx(isLight ? "text-primary" : "text-secondary")}
                dangerouslySetInnerHTML={{ __html: successMessage }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(
        "w-full border-t-4 border-secondary dark:border-primary",
        isLight ? "bg-secondary text-primary" : "bg-primary text-secondary"
      )}
    >
      <div className="container relative py-8 from-desktop:py-10">
        {(title || description) && (
          <div className="pb-5 flex-col gap-5">
            {title && (
              <h2
                className={cx(
                  "text-xl from-desktop:text-2xl font-semibold",
                  isLight ? "text-primary" : "text-secondary"
                )}
              >
                {title}
              </h2>
            )}

            {description && (
              <p
                className={cx(isLight ? "text-primary" : "text-secondary")}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5 w-full">
            <div className="flex gap-7 from-desktop:gap-10 only-mobile:flex-col items-center">
              <div className="w-full relative">
                <input
                  type="text"
                  placeholder="Adres e-mail"
                  className={cx(
                    "border-2 outline-none  dark:bg-secondary  dark:text-primary p-3 text-base w-full",
                    isLight
                      ? "bg-secondary text-primary"
                      : "bg-primary text-secondary",
                    errors.email
                      ? "border-action"
                      : isLight
                      ? "border-primary"
                      : "border-secondary"
                  )}
                  {...register("email", {
                    required: "Adres email jest wymagany",
                    pattern: {
                      value: /^\S+@\S+\.\S+$/,
                      message: "Nieprawidłowy adres email",
                    },
                  })}
                  onFocus={onFocus}
                />
                {errors.email && (
                  <p className="text-action text-sm  absolute -bottom-5 left-0">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className="ml-auto mr-0 min-w-52 only-mobile:hidden">
                <BaseButton
                  type="submit"
                  size="BASIC"
                  variant={isLight ? "PRIMARY" : "SECONDARY"}
                  className="w-full"
                >
                  {actionText || "Zapisz się"}
                </BaseButton>
              </div>
            </div>
            <div className="relative">
              <fieldset>
                <label
                  htmlFor="newsletter-legal"
                  className="flex gap-2 items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id="newsletter-legal"
                    className="hidden peer"
                    {...register("legal", {
                      required: "Zgoda jest wymagana.",
                    })}
                    onFocus={onFocus}
                    readOnly={sendingStatus === "PENDING"}
                  />
                  <div
                    className={cx(
                      "w-5 h-5 border-2 relative",
                      "after:w-3 after:h-3 after:absolute after:top-[2px] after:left-[2px] peer-checked:after:bg-action",
                      isLight ? "border-primary" : "border-secondary"
                    )}
                  />
                  <span
                    className={cx(isLight ? "text-primary" : "text-secondary")}
                  >
                    {legal}
                  </span>
                </label>
              </fieldset>
              {errors.legal && (
                <p className="text-action text-sm  absolute -bottom-5 left-0">
                  {errors.legal.message}
                </p>
              )}
            </div>
            <div className="w-full only-mobile:block hidden">
              <BaseButton
                type="submit"
                size="BASIC"
                variant={isLight ? "PRIMARY" : "SECONDARY"}
                className="w-full"
                disabled={sendingStatus === "PENDING"}
              >
                {actionText || "Zapisz się"}
              </BaseButton>
            </div>
            {sendingStatus && (
              <>
                {sendingStatus === "ERROR" && (
                  <span className="text-action text-sm from-desktop:whitespace-nowrap">
                    Wystąpił błąd podczas wysyłania formularza. Spróbuj wysłać
                    jeszcze raz.
                  </span>
                )}
                {sendingStatus === "PENDING" && (
                  <span
                    className={cx(
                      "text-sm from-desktop:whitespace-nowrap",
                      isLight ? "text-primary" : "text-secondary"
                    )}
                  >
                    Formularz w trakcie wysyłania ....
                  </span>
                )}
              </>
            )}
          </div>
          <GoogleReCaptcha
            onVerify={setTokenFunc}
            refreshReCaptcha={refreshReCaptcha}
          />
        </form>
      </div>
    </div>
  );
}
